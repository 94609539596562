/** ***************************************************************************
 * Common / Templates / Error
 *************************************************************************** */

 body {
  background-image: linear-gradient(270deg, var(--lm-global--palette-dusty-blue) 0%, var(--lm-global--palette-light-blue) 71%, var(--lm-global--palette-dark-blue) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

main {
  align-self: self-start;
  padding-inline: .875rem;
  padding-block: 3.188rem;
  text-align: center;

  .logo {
    width: clamp(10.708rem, 20vw, 13.938rem);
    margin-inline: auto;
    margin-block-end: 5rem;
  }

  .lm-u-button.lm-c-button {
    margin-block-start: 1.25rem;
  }

  @media (min-height: 25rem) {
    align-self: center;
  }

}
